/** @format */

import { useEffect } from "react";
import { useState } from "react";
import Box from "@mui/material/Box/Box";
import Button from "@mui/material/Button/Button";
import {
  DialogComponent,
  TableComponent,
  UserAutoCompleteSelect,
} from "../../../Components";
import {
  IPageProps,
  NetworkDto,
} from "../../../Models";
import { UserApiService } from "../../../Services/UserService";
import {
  Data,
  NetworkHeadCell,
  createSmartTariffNetworkData,
} from "../../../Components/TableComponent/utils";
import { NetworkService } from "../../../Services";

function SmartTariff(props: IPageProps) {
  const userApiService = new UserApiService();
  const networkService = new NetworkService();
  const [dialogMessage, setDialogMessage] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<string>("");
  const [selectedUserNetworks, setSelectedUserNetworks] = useState<NetworkDto[]>([]);
  const [selectedNetwork, setSelectedNetwork] = useState<NetworkDto>();
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [selectedUserNetworksRows, setSelectedUserNetworksRows] = useState<
    Data[]
  >([]);

  useEffect(() => {
    if (selectedUser != "") {
      fetchSelectedUserNetworks(selectedUser);
    } else {
      setSelectedUserNetworksRows([]);
    }
  }, [selectedUser]);

  const fetchSelectedUserNetworks = async (userEmail: string) => {
    try {
      props.setIsLoading(true);
      if(userEmail == "" || userEmail == null || userEmail == undefined){
        setSelectedUserNetworksRows([]);
        return;
      }
      const res = await userApiService.GetUserNetworks(userEmail);
      setSelectedUserNetworks(res.data);
      const networkRows = res.data.map((r: NetworkDto) =>
        createSmartTariffNetworkData(r)
      );
      setSelectedUserNetworksRows(networkRows);
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      props.setIsLoading(false);
    }
  };

  const setSmartTariffOnNetwork = async (networkUid: string) => {
    try {
      const network = selectedUserNetworks.find(x=>x.uid===networkUid);

      if(network == undefined)
        throw new Error("Try again later");

      setDialogMessage(
        `Are you sure you want to ${network.smartTariffEnabled ? "disable" : "enable"} Smart Tariff on ${network.name} network?`
      );
      setSelectedNetwork(network);
      setShowConfirmDialog(true);

    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      props.setIsLoading(false);
    }
  };

  const innerSetSmartTariffOnNetwork = async () => {
    try {
      props.setIsLoading(true);

      if(selectedNetwork == undefined)
        throw new Error("Try again later");

      var res = await networkService.SetSmartTariffOnNetwork(selectedNetwork.id,(!selectedNetwork.smartTariffEnabled) ?? true);
      if(!res)
        throw new Error("Error while trying to set the smart tariff on the network");

      await fetchSelectedUserNetworks(selectedUser);
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      props.setIsLoading(false);
    }
  };

  return (
    <div>
      {props.isAdmin && (
        <div>
          <Box marginTop={3}>
            <Box marginTop={5}>
              <Box>
                <h3>Choose a user</h3>
                <UserAutoCompleteSelect
                  disabled={false}
                  label="User"
                  handleAlertShow={props.handleAlertShow}
                  paramKey={"email"}
                  setParam={setSelectedUser}
                  styleToRender={{ mt: "10px" }}
                />
              </Box>
              {selectedUserNetworksRows.length !== 0 && (
                <Box marginTop={3}>
                  <TableComponent
                    key={"UserNetworks"}
                    tabTitle={`User Networks`}
                    rows={selectedUserNetworksRows}
                    headCells={NetworkHeadCell}
                    selectionIcon={null}
                    selectionAction={null}
                    rowAction={setSmartTariffOnNetwork}
                    rowActionIcon={null}
                    rowActionIconWithLambda={(row) =>
                      row.elements == undefined ? (
                        <></>
                      ) : row.elements[row.elements.length - 1] == "false" ? (
                        <Button variant="contained">Attiva</Button>
                      ) : (
                        <></>
                      )
                    }
                    reload={()=>fetchSelectedUserNetworks(selectedUser)}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </div>
      )}
      <DialogComponent
        dialogTitle="Warning"
        dialogMessage={dialogMessage}
        closable
        showDialog={{
          get: showConfirmDialog,
          set: setShowConfirmDialog,
        }}
        confirmDialogCallback={innerSetSmartTariffOnNetwork}
      />
    </div>
  );
}

export default SmartTariff;
