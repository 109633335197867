import Box from '@mui/material/Box/Box';
import Tab from '@mui/material/Tab/Tab';
import Tabs from '@mui/material/Tabs/Tabs';
import React, { Dispatch, SetStateAction } from 'react';
import { tabPanelPropsCreate } from '../../Components';

interface MenuComponentProps {
	value: {
		get: number;
		set: Dispatch<SetStateAction<number>>;
	};
	isAdmin: boolean;
}

function MenuComponent({ value, isAdmin }: MenuComponentProps) {
	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		value.set(newValue);
	};

	return (
		<Box
			boxShadow={1}
			sx={{
				border: 1,
				borderColor: 'divider',
				backgroundColor: 'white',
				width: '20%',
				height: '90%',
				overflow: 'auto',
			}}
		>
			<Box
				sx={{
					p: '1%',
					display: 'flex',
					justifyContent: 'center',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<img
					alt="daze logo"
					src="logo.svg"
					style={{
						padding: '2%',
						width: '80%',
						height: '80%',
						marginBottom: '10%',
						marginTop: '10%',
					}}
				/>
			</Box>
			<Box>
				<Tabs
					orientation="vertical"
					value={value.get}
					onChange={handleChange}
					aria-label="basic tabs example"
				>
					<Tab
						label="Files"
						{...tabPanelPropsCreate(0)}
						sx={{ height: '60px' }}
					/>
					<Tab
						label="Networks"
						{...tabPanelPropsCreate(1)}
						sx={{ height: '60px' }}
					/>
					<Tab
						label="Ota Deprecation"
						{...tabPanelPropsCreate(2)}
						sx={{ height: '60px' }}
					/>
					<Tab
						label="App Configurations"
						{...tabPanelPropsCreate(3)}
						sx={{ height: '60px' }}
					/>
					{isAdmin && (
						<Tab
							label="Telemetries Generation"
							{...tabPanelPropsCreate(4)}
							sx={{ height: '60px' }}
						/>
					)}
					{isAdmin && (
						<Tab
							label="Attributes management"
							{...tabPanelPropsCreate(5)}
							sx={{ height: '60px' }}
						/>
					)}

					{isAdmin && (
						<Tab
							label="Firmware Update"
							{...tabPanelPropsCreate(6)}
							sx={{ height: '60px' }}
						/>
					)}
					{isAdmin && (
						<Tab
							label="Failed Firmware Updates"
							{...tabPanelPropsCreate(7)}
							sx={{ height: '60px' }}
						/>
					)}
					{isAdmin && (
						<Tab
							label="RFID"
							{...tabPanelPropsCreate(8)}
							sx={{ height: '60px' }}
						/>
					)}
					{isAdmin && (
						<Tab
							label="Edit Networks"
							{...tabPanelPropsCreate(9)}
							sx={{ height: '60px' }}
						/>
					)}
					{isAdmin && (
						<Tab
							label="Dashboard"
							{...tabPanelPropsCreate(10)}
							sx={{ height: '60px' }}
						/>
					)}
					{isAdmin && (
						<Tab
							label="Users"
							{...tabPanelPropsCreate(11)}
							sx={{ height: '60px' }}
						/>
					)}
					{isAdmin && (
						<Tab
							label="CPO"
							{...tabPanelPropsCreate(12)}
							sx={{ height: '60px' }}
						/>
					)}
					{isAdmin && (
						<Tab
							label="Manual Updates"
							{...tabPanelPropsCreate(13)}
							sx={{ height: '60px' }}
						/>
					)}
					{isAdmin && (
						<Tab
							label="Unsupported CPO Devices"
							{...tabPanelPropsCreate(14)}
							sx={{ height: '60px' }}
						/>
					)}
					{isAdmin && (
						<Tab
							label="Privacy"
							{...tabPanelPropsCreate(15)}
							sx={{ height: '60px' }}
						/>
					)}
				</Tabs>
			</Box>
		</Box>
	);
}

export default MenuComponent;
