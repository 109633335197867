import { UserControllerUrl, apiURL } from ".";
import { NetworkDto } from "../Models";
import ServiceResponseDto, {
  GenericServiceResponseDto,
} from "../Models/ServiceResponseDto";
import SetUserPrivilegeRequestDto from "../Models/SetUserPrivilegeRequestDto";
import { AuthService } from "./AuthService";

export class UserService {
  authService: AuthService = new AuthService(apiURL.backofficeApiUrl);
  async SetUserAsSuperAdmin(
    setUserPrivilegeRequest: SetUserPrivilegeRequestDto
  ): Promise<GenericServiceResponseDto> {
    var url = `${UserControllerUrl}/SetUserAsSuperAdmin`;
    return await this.authService.PostAsyncAndReturn<GenericServiceResponseDto>(
      url,
      setUserPrivilegeRequest
    );
  }
}

export class UserApiService {
  authService: AuthService = new AuthService(apiURL.appApiUrl);
  async GetUserNetworks(
    userEmail: string
  ): Promise<ServiceResponseDto<NetworkDto[]>> {
    var url = `users/${userEmail}/networks`;
    return await this.authService.GetAsync<ServiceResponseDto<NetworkDto[]>>(
      url
    );
  }
}
