import DownloadIcon from "@mui/icons-material/Download";
import { Button, ButtonGroup, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { GridColDef } from "@mui/x-data-grid/models/colDef";
import { Buffer } from "buffer";
import moment from "moment";
import { useEffect, useState, useMemo } from "react";
import { DialogComponent } from "../../Components";
import { IPageProps, PageDto } from "../../Models";
import {
  PrivacyComponent,
  UserPrivacyAcceptanceDto,
} from "../../Models/UserPrivacyAcceptanceDto";
import { PrivacyService } from "../../Services/PrivacyService";
import {
  type MRT_ColumnDef,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";

type UserPrivacyComponent = {};
type UserPrivacyAcceptanceRow = {
  id?: string;
  email?: string;
  componentName?: string;
  privacy?: string;
  privacyDate?: string;
  marketing?: string;
  marketingDate?: string;
  components?: UserPrivacyAcceptanceRow[];
};
function PrivacyPage(props: IPageProps) {
  const service = new PrivacyService();
  const [currentTab, setCurrentTab] = useState("Reset");
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  const [privacyRows, setPrivacyRows] = useState<UserPrivacyAcceptanceRow[]>(
    []
  );
  const [privacyRowsTotalCount, setPrivacyRowsTotalCount] = useState<number>(0);

  const [paginationModel, setPaginationModel] = useState({
    pageIndex: 0,
    pageSize: 5,
  });

  const skip = paginationModel.pageIndex * paginationModel.pageSize;
  const take = paginationModel.pageSize;

  const columns: MRT_ColumnDef<UserPrivacyAcceptanceRow, string>[] = [
    {
      accessorKey: "email",
      header: "Email",
    },
    {
      accessorKey: "componentName",
      header: "Component",
    },
    {
      accessorKey: "privacy",
      header: "Privacy",
    },
    {
      accessorKey: "privacyDate",
      header: "Privacy confirmation date",
    },
    {
      accessorKey: "marketing",
      header: "Marketing",
    },
    {
      accessorKey: "marketingDate",
      header: "Marketing confirmation date",
    },
  ];

  const table = useMaterialReactTable({
    // @ts-ignore
    columns,
    data: privacyRows,
    enableRowSelection: false,
    onPaginationChange: setPaginationModel,
    initialState: {
      expanded: true,
    },
    state: { pagination: paginationModel },
    enableExpandAll: true,
    enableColumnOrdering: false,
    enableGlobalFilter: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableHiding: false,
    getSubRows: (row) => row.components,
    enableExpanding: true,
    filterFromLeafRows: true,
    paginateExpandedRows: true,
    enablePagination: true,
    manualPagination: true,
    rowCount: privacyRowsTotalCount,
    paginationDisplayMode: "pages",
  });

  const privacyResetDialogMessage =
    "Are you sure you want to do a privacy acceptance reset for all users?";

  const handleResetPrivacy = async () => {
    props.setIsLoading(true);
    try {
      await service.ResetPrivacy();
      props.handleAlertShow("success", "Privacy has been reset for all users");
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      props.setIsLoading(false);
    }
  };

  async function getUsersPrivacyAcceptance(skip: number, take: number) {
    try {
      props.setIsLoading(true);
      const response: PageDto = (
        await service.GetUserPrivacyAcceptance(skip, take)
      ).data;
      const elements: UserPrivacyAcceptanceDto[] = response.elements;
      setPrivacyRowsTotalCount(response.numElements);
      const rows = elements.map((r) => {
        return {
          id: r.email,
          email: r.email,
          components: r.components.map((c) => {
            return {
              componentName: PrivacyComponent[c.appName],
              privacy: c.privacy ? "Accepted" : "Not accepted",
              privacyDate:
                c.privacyDate === undefined || c.privacyDate === null
                  ? ""
                  : moment(c.privacyDate).format("DD/MM/YYYY HH:mm:ss"),
              marketing: c.marketing ? "Accepted" : "Not accepted",
              marketingDate:
                c.marketingDate === undefined || c.marketingDate === null
                  ? ""
                  : moment(c.marketingDate).format("DD/MM/YYYY HH:mm:ss"),
            };
          }),
        };
      });
      setPrivacyRows(rows);
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
      props.setIsLoading(false);
    } finally {
      props.setIsLoading(false);
    }
  }

  const handleExport = async () => {
    props.setIsLoading(true);
    try {
      const res = await service.ExportUsersPrivacy();
      var blob = new Blob([Buffer.from(res.data, "base64")], { type: "xls" });
      var a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = "exported_users_privacy.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      props.setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      await getUsersPrivacyAcceptance(skip, take);
    };
    if (currentTab === "UserPrivacy") {
      fetch();
    }
  }, [paginationModel, currentTab]);

  return (
    <>
      <ButtonGroup variant="outlined" aria-label="outlined button group">
        <Button
          variant={currentTab === "Reset" ? "contained" : "outlined"}
          onClick={() => setCurrentTab("Reset")}
        >
          Reset privacy
        </Button>
        <Button
          variant={currentTab === "UserPrivacy" ? "contained" : "outlined"}
          onClick={() => setCurrentTab("UserPrivacy")}
        >
          User privacy acceptance
        </Button>
      </ButtonGroup>
      {currentTab === "Reset" && (
        <div>
          <DialogComponent
            dialogTitle="Warning"
            dialogMessage={privacyResetDialogMessage}
            closable
            showDialog={{
              get: showConfirmDialog,
              set: setShowConfirmDialog,
            }}
            confirmDialogCallback={handleResetPrivacy}
          />
          <Button
            sx={{ width: 200, p: 1, m: 2 }}
            onClick={() => setShowConfirmDialog(true)}
            variant="outlined"
          >
            Reset privacy for all users
          </Button>
        </div>
      )}
      {currentTab === "UserPrivacy" && (
        <>
          <div>
            <IconButton
              onClick={handleExport}
              sx={{
                width: "200px",
                height: "60px",
                fontSize: "15px",
                borderRadius: "10px",
              }}
              color="primary"
            >
              <label
                style={{
                  marginRight: "10px",
                  textTransform: "uppercase",
                  cursor: "pointer",
                }}
              >
                download
              </label>
              <DownloadIcon fontSize="medium"></DownloadIcon>
            </IconButton>
          </div>
          <MaterialReactTable table={table} />;
        </>
      )}
    </>
  );
}

export default PrivacyPage;
