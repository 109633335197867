import { AuthService, PrivacyControllerUrl, apiURL } from ".";
import { PageDto, ServiceResponseDto } from "../Models";
import { UserPrivacyAcceptanceDto } from "../Models/UserPrivacyAcceptanceDto";

export class PrivacyService {
    authService: AuthService = new AuthService(apiURL.backofficeApiUrl);
    async ResetPrivacy(): Promise<void> {
        const url = `${PrivacyControllerUrl}/ResetPrivacy`;
        return await this.authService.PostAsyncNoContent(url);
    }

    async GetUserPrivacyAcceptance(skip: number, take: number): Promise<ServiceResponseDto<PageDto<UserPrivacyAcceptanceDto>>> {
        const url = `${PrivacyControllerUrl}/GetUserPrivacyAcceptance?skip=${skip}&take=${take}`;
        return await this.authService.GetAsync<ServiceResponseDto<PageDto<UserPrivacyAcceptanceDto>>>(url);
    }
    async ExportUsersPrivacy(): Promise<ServiceResponseDto<any>> {
        const url = `${PrivacyControllerUrl}/ExportUserPrivacy`;
        return await this.authService.GetAsync<ServiceResponseDto<any>>(url);
    }
}