export { AttributesService } from "./AttributesService";
export { AuthService } from "./AuthService";
export { ConfigurationService } from "./ConfigurationService";
export { FilesService } from "./FilesService";
export { NetworkService } from "./NetworkService";
export { OtaService as DeprecateOtaService } from "./OtaService";
export { TelemetriesGenerationService } from "./TelemetriesGenerationService";
export { UserService } from "./UserService";

export const DeprecateOtaToolControllerUrl = "Ota";
export const UserControllerUrl = "User";
export const DeviceControllerUrl = "Device";
export const NetworkControllerUrl = "Network";
export const ConfigurationControllerUrl = "Configuration";
export const CustomerControllerUrl = "Customers";
export const PrivacyControllerUrl = "Privacy";
export interface ApiUrl {
  backofficeApiUrl: string;
  appApiUrl: string;
}

export const apiURL: ApiUrl = {
  backofficeApiUrl: process.env.REACT_APP_API_URL || "",
  appApiUrl: process.env.REACT_APP_DAZE_API_URL || "",
};
