import createTheme from "@mui/material/styles/createTheme";

declare module '@mui/material/styles' {
  interface Palette {
    DazeDarkBlue: Palette['primary'];
    DazeBlue: Palette['primary'];
    DazeYellow: Palette['primary'];
    DazeWhite: Palette['primary'];
    DazeBlack: Palette['primary'];
    DazeGray: Palette['primary'];
    DazeTransparent: Palette['primary'];
  }
  interface PaletteOptions {
    DazeDarkBlue: PaletteOptions['primary'];
    DazeBlue: PaletteOptions['primary'];
    DazeYellow: PaletteOptions['primary'];
    DazeWhite: PaletteOptions['primary'];
    DazeBlack: PaletteOptions['primary'];
    DazeGray: PaletteOptions['primary'];
    DazeTransparent: PaletteOptions['primary'];
  }
}

export const devTheme = createTheme({
    palette: {
      primary:{
        light: "#223AA3",
        main: "#223AA3",
        dark: "#000e4e",
        contrastText: "#fff",
      },
      DazeDarkBlue: {
        main: "#000e4e",
      }, 
      DazeBlue:{
        main: "#223AA3",
      },
      DazeYellow: {
        main: "#F2B200",
      },
      DazeWhite: {
        main: "#fff",
      },
      DazeBlack: {
        main: "#000000",
      },
      DazeGray: {
        main: "#A9A9A9",
      },
      DazeTransparent: {
        main: "rgba(255,255,255,0)",
      }
    },
  });

  export const prodTheme = createTheme({
    palette: {
      primary:{
        light: "#223AA3",
        main: "#223AA3",
        dark: "#000e4e",
        contrastText: "#fff",
      },
      DazeDarkBlue: {
        main: "#000e4e",
      }, 
      DazeBlue:{
        main: "#223AA3",
      },
      DazeYellow: {
        main: "#F2B200",
      },
      DazeWhite: {
        main: "#fff",
      },
      DazeBlack: {
        main: "#000000",
      },
      DazeGray: {
        main: "#A9A9A9",
      },
      DazeTransparent: {
        main: "rgba(255,255,255,0)",
      }
    },
  });

  