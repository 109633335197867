export enum PrivacyComponent {
  App,
  B2C,
}
interface UserPrivacyAcceptanceComponentDto {
  appName: PrivacyComponent;
  privacy: boolean;
  privacyDate?: Date;
  marketing: boolean;
  marketingDate?: Date;
}
export interface UserPrivacyAcceptanceDto {
  email: string;
  components: UserPrivacyAcceptanceComponentDto[];
}
