import { AlertColor } from '@mui/material/Alert/Alert';
import Box from '@mui/material/Box/Box';
import React, { useState } from 'react';
import { IPageProps } from '../../Models';
import {
	AppConfigurations,
	Dashboard,
	DeprecateOta,
	EditNetworks,
	Files,
	FirmwareUpdate,
	Networks,
	TelemetriesGeneration,
	Users,
} from '../../Pages';
import AttributesManagement from '../../Pages/AttributesManagement/AttributesManagement';
import CPOPage from '../../Pages/CPO/CPO';
import UnsupportedCPODevicesPage from '../../Pages/CPO/UnsupportedCPODevices';
import PrivacyPage from '../../Pages/Privacy/Privacy';
import Rfid from '../../Pages/Rfid/Rfid';
import FailedFirmwareUpdate from '../../Pages/Updates/FailedFirmwareUpdate';
import ManualUpdates from '../../Pages/Updates/ManualUpdates';
import AlertComponent from '../AlertComponent/AlertComponent';
import Loader from '../Loader/Loader';
import { TabPanel } from '../TabPanel/TabPanel';

interface PagesComponentProps {
	value: number;
	isAdmin: boolean;
}

function PagesComponent(props: PagesComponentProps) {
	//loader
	const [isLoading, setIsLoading] = useState<boolean>(false);

	//alert
	const [showAlert, setShowAlert] = useState(false);
	const [alertType, setAlertType] = useState<AlertColor>();
	const [alertText, setAlertText] = useState<string>();

	React.useEffect(() => {
		setShowAlert(false);
	}, [props.value]);

	const handleAlertShow = (type: AlertColor, text: string) => {
		if (text === undefined || text.length === 0) {
			setShowAlert(false);
			return;
		}
		setAlertType(type);
		setAlertText(text);
		setShowAlert(true);
	};

	const pageProps: IPageProps = {
		isAdmin: props.isAdmin,
		handleAlertShow: handleAlertShow,
		setIsLoading: setIsLoading,
	};

	return (
		<Box
			boxShadow={1}
			sx={{
				border: 1,
				borderColor: 'divider',
				backgroundColor: 'white',
				height: '80%',
				overflow: 'auto',
			}}
		>
			<TabPanel value={props.value} index={0}>
				<Files {...pageProps} />
			</TabPanel>
			<TabPanel value={props.value} index={1}>
				<Networks {...pageProps} />
			</TabPanel>
			<TabPanel value={props.value} index={2}>
				<DeprecateOta {...pageProps} />
			</TabPanel>
			<TabPanel value={props.value} index={3}>
				<AppConfigurations {...pageProps} />
			</TabPanel>
			{props.isAdmin && (
				<>
					<TabPanel value={props.value} index={4}>
						<TelemetriesGeneration {...pageProps} />
					</TabPanel>
					<TabPanel value={props.value} index={5}>
						<AttributesManagement {...pageProps} />
					</TabPanel>
					<TabPanel value={props.value} index={6}>
						<FirmwareUpdate {...pageProps} />
					</TabPanel>
					<TabPanel value={props.value} index={7}>
						<FailedFirmwareUpdate {...pageProps} />
					</TabPanel>
					<TabPanel value={props.value} index={8}>
						<Rfid {...pageProps} />
					</TabPanel>
					<TabPanel value={props.value} index={9}>
						<EditNetworks {...pageProps} />
					</TabPanel>
					<TabPanel value={props.value} index={10}>
						<Dashboard {...pageProps} />
					</TabPanel>
					<TabPanel value={props.value} index={11}>
						<Users {...pageProps} />
					</TabPanel>
					<TabPanel value={props.value} index={12}>
						<CPOPage {...pageProps} />
					</TabPanel>
					<TabPanel value={props.value} index={13}>
						<ManualUpdates {...pageProps} />
					</TabPanel>
					<TabPanel value={props.value} index={14}>
						<UnsupportedCPODevicesPage {...pageProps} />
					</TabPanel>
					<TabPanel value={props.value} index={15}>
						<PrivacyPage {...pageProps} />
					</TabPanel>
				</>
			)}

			<AlertComponent
				showAlert={{ get: showAlert, set: setShowAlert }}
				alertText={alertText}
				alertType={alertType}
			/>
			<Loader isLoading={isLoading} />
		</Box>
	);
}

export default PagesComponent;
