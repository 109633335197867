import React, { useEffect, useState } from "react";
import IPageProps from "../../Models/IPageProps";
import { CPOService } from "../../Services/CPOService";
import ServiceResponseDto, {
    GenericServiceResponseDto,
} from "../../Models/ServiceResponseDto";
import CPODto from "../../Models/CPODto";
import {
    CPOHeadCell,
    Data,
    createDataFromCPO,
    createOtaData,
} from "../../Components/TableComponent/utils";
import { TableComponent, CPOAutoCompleteSelect } from "../../Components";
import EditIcon from "@mui/icons-material/Edit";
import {
    Box,
    Button, ButtonGroup,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    IconButton,
    TextField,
} from "@mui/material";
import { UpdateCPODto } from "../../Models";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import CpoDetails from "../../Components/TableComponent/CpoDetails";

const enum EditCPOMode {
    AddNewCPO,
    EditCPO,
}

function CPOPage(props: IPageProps) {
    const cpoService = new CPOService();
    const [cpoName, setCPOName] = useState<string>("");
    const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
    const [editMode, setEditMode] = useState<EditCPOMode>();
    const [cpoRows, setCPORows] = useState<Data[]>([]);
    const [cpos, setCPOs] = useState<CPODto[]>([]);
    const [selectedCPO, setSelectedCPO] = useState<CPODto | null>(null);
    const [currentTab, setCurrentTab] = React.useState("List");

    const getAllCPOs = async () => {
        try {
            props.setIsLoading(true);
            var cpoResponse: ServiceResponseDto<CPODto[]> =
                await cpoService.GetCPOs();
            const responseData = cpoResponse.data.map((r: CPODto) =>
                createDataFromCPO(r)
            );
            setCPORows(responseData);
            setCPOs(cpoResponse.data);
        } catch (error: any) {
            props.handleAlertShow("error", error.message);
        } finally {
            props.setIsLoading(false);
        }
    };
    const onEditCPO = (row: string) => {
        var cpo = cpos.find((c) => c.name === row);
        if (cpo !== undefined) {
            setSelectedCPO(cpo);
            setEditMode(EditCPOMode.EditCPO);
            setEditDialogOpen(true);
        }
    };
    const onSaveEditDialog = async () => {
        if (selectedCPO === null) {
            return;
        }
        try {
            props.setIsLoading(true);
            if (editMode === EditCPOMode.EditCPO) {
                await cpoService.UpdateCPO(selectedCPO.name, {
                    displayName: selectedCPO.displayName,
                    visible: selectedCPO.visible,
                });
            } else {
                await cpoService.CreateCPO({
                    name: selectedCPO.name,
                    visible: selectedCPO.visible,
                });
            }
            setEditDialogOpen(false);
            await getAllCPOs();
        } catch (error: any) {
            props.handleAlertShow("error", error.message);
        } finally {
            closeEditDialog();
            props.setIsLoading(false);
        }
    };

    const setTab = (newTab: string) => {
        if (newTab === currentTab) {
            return;
        }
        if (newTab === "List") {
            setCurrentTab("List");
        } else if (newTab === "Details") {
            setCurrentTab("Details");
        }
    };
    const closeEditDialog = () => {
        setEditDialogOpen(false);
    };
    const addNewCPO = () => {
        setSelectedCPO({
            name: "",
            displayName: "",
            visible: true,
            otas: []
        });
        setEditMode(EditCPOMode.AddNewCPO);
        setEditDialogOpen(true);
    };
    useEffect(() => {
        getAllCPOs();
    }, []);

    useEffect(() => {
        if (cpoName === "") {
            return;
        }
        let cpo = cpos.find(x => x.name == cpoName);
        if (cpo == undefined)
            return;
        setSelectedCPO(cpo)
    }, [cpoName]);
    return (
        <>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
                <Button
                    variant={
                        currentTab === "List" ? "contained" : "outlined"
                    }
                    onClick={() => setTab("List")}
                >
                    List
                </Button>
                <Button
                    variant={
                        currentTab === "Details" ? "contained" : "outlined"
                    }
                    onClick={() => setTab("Details")}
                >
                    Details
                </Button>
            </ButtonGroup>
            {currentTab === "List" && (
                <Box>
                    <div style={{ width: "45%", marginTop: "2.5rem" }}>
                        <IconButton
                            onClick={addNewCPO}
                            sx={{
                                width: "180px",
                                height: "60px",
                                fontSize: "15px",
                                borderRadius: "10px",
                            }}
                            color="primary"
                        >
                            <label
                                style={{
                                    marginRight: "10px",
                                    textTransform: "uppercase",
                                    cursor: "pointer",
                                }}
                            >
                                Add CPO
                            </label>
                            <AddCircleRoundedIcon fontSize="large" />
                        </IconButton>
                    </div>
                    {cpoRows.length !== 0 && (
                        <TableComponent
                            key={"CPO"}
                            tabTitle={`CPOs`}
                            rows={cpoRows}
                            headCells={CPOHeadCell}
                            selectionIcon={null}
                            selectionAction={null}
                            rowAction={onEditCPO}
                            rowActionIcon={props.isAdmin ? <EditIcon /> : null}
                            reload={getAllCPOs}
                        />
                    )}
                    {selectedCPO !== null && (
                        <Dialog open={editDialogOpen} onClose={closeEditDialog}>
                            <DialogTitle>
                                {editMode === EditCPOMode.AddNewCPO ? "Add CPO" : "Edit CPO"}
                            </DialogTitle>
                            <DialogContent>
                                {editMode === EditCPOMode.EditCPO ? (
                                    <>
                                        <TextField
                                            margin="dense"
                                            id="name"
                                            label="Display Name"
                                            type="text"
                                            fullWidth
                                            variant="standard"
                                            defaultValue={selectedCPO.displayName}
                                            onChange={(event) => {
                                                setSelectedCPO({
                                                    ...selectedCPO,
                                                    displayName: event.target.value,
                                                });
                                            }}
                                        />
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={selectedCPO?.visible}
                                                        onChange={(event) => {
                                                            setSelectedCPO({
                                                                ...selectedCPO,
                                                                visible: event.target.checked,
                                                            });
                                                        }}
                                                    />
                                                }
                                                label="Visible"
                                            />
                                        </FormGroup>
                                    </>
                                ) : (
                                    <>
                                        <TextField
                                            autoFocus
                                            margin="dense"
                                            id="name"
                                            label="Name"
                                            type="text"
                                            fullWidth
                                            variant="standard"
                                            defaultValue={selectedCPO.name}
                                            onChange={(event) => {
                                                setSelectedCPO({
                                                    ...selectedCPO,
                                                    name: event.target.value,
                                                });
                                            }}
                                        />
                                    </>
                                )}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={closeEditDialog}>Cancel</Button>
                                <Button onClick={onSaveEditDialog}>Save</Button>
                            </DialogActions>
                        </Dialog>
                    )}
                </Box>
            )}
            {currentTab === "Details" && (
                <Box>
                    <CPOAutoCompleteSelect 
                        disabled={false}
                        label="Search for CPO"
                        handleAlertShow={props.handleAlertShow}
                        paramKey="id"
                        setParam={setCPOName}
                        styleToRender={{ mt: "30px", mb: "20px" }}
                    />

                    {cpoName != "" && (
                        <CpoDetails
                            cpoName={selectedCPO?.displayName}
                            cpoIsVisible={true}
                            cpoOtasVersions={selectedCPO?.otas.map(x=>createOtaData(x))} />
                    )}
                </Box>
            )}

        </>
    );
}

export default CPOPage;
