import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { Data, HeadCell } from "./utils";
import { EnhancedTableToolbar } from "./EnhancedTableToolbar";
import { EnhancedTableHead } from "./EnhancedTableHead";
import IconButton from "@mui/material/IconButton/IconButton";

interface TableComponentProps {
  rows: Data[];
  headCells: readonly HeadCell[];
  selectionIcon: JSX.Element | null;
  selectionAction: ((selected: readonly string[]) => void) | null;
  rowActionIconWithLambda?: ((row: Data) => JSX.Element | null) | undefined;
  rowAction: ((id: string) => void) | null;
  rowActionIcon: JSX.Element | null;
  tabTitle: string;
  reload: (() => Promise<void>) | (() => void) | null;
}

function TableComponent(props: TableComponentProps) {
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const selectable = props.selectionAction !== null;

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = props.rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (email: string) => selected.indexOf(email) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.rows.length) : 0;

  const rowAction = (row: Data) => {
    if (props.rowAction !== null) {
      props.rowAction(row.firstElement);
    }
  };

  const selectionAction = () => {
    if (props.selectionAction !== null) {
      props.selectionAction(selected);
      setSelected([]);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          deselect={() => setSelected([])}
          selectionIcon={props.selectionIcon}
          selectionAction={selectionAction}
          numSelected={selected.length}
          tabTitle={props.tabTitle}
          reload={props.reload}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              selectable={selectable}
              numSelected={selected.length}
              onSelectAllClick={selectable ? handleSelectAllClick : () => {}}
              onRequestSort={() => {}}
              rowCount={props.rows.length}
              headCells={props.headCells}
            />
            <TableBody>
              {props.rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = selectable
                    ? isSelected(row.id)
                    : false;
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer", height: "48px" }}
                    >
                      <TableCell
                        onClick={(event) => {
                          if (selectable) {
                            handleClick(event, row.id);
                          }
                        }}
                        padding="checkbox"
                      >
                        {selectable && (
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell
                        onClick={(event) => {
                          if (selectable) {
                            handleClick(event, row.id);
                          }
                        }}
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.firstElement}
                      </TableCell>
                      {row.elements?.map((element) => (
                        <TableCell
                          onClick={(event) => {
                            if (selectable) {
                              handleClick(event, row.id);
                            }
                          }}
                          align="right"
                        >
                          {element}
                        </TableCell>
                      ))}
                      {props.rowActionIcon && props.rowAction !== null ? (
                        <TableCell align="right">
                          <IconButton onClick={(e) => rowAction(row)}>
                            {props.rowActionIcon}
                          </IconButton>
                        </TableCell>
                      ) : props.rowActionIconWithLambda != undefined && props.rowAction !== null ? (
                        <TableCell align="right">
                          <IconButton onClick={(e) => rowAction(row)}>
                            {props.rowActionIconWithLambda(row)}
                          </IconButton>
                        </TableCell>
                      ) : (
                        <></>
                      )}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={props.rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

export default TableComponent;
